exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-bookings-js": () => import("./../../../src/pages/bookings.js" /* webpackChunkName: "component---src-pages-bookings-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-private-events-js": () => import("./../../../src/pages/private-events.js" /* webpackChunkName: "component---src-pages-private-events-js" */),
  "component---src-pages-venues-js": () => import("./../../../src/pages/venues.js" /* webpackChunkName: "component---src-pages-venues-js" */),
  "component---src-pages-vouchers-js": () => import("./../../../src/pages/vouchers.js" /* webpackChunkName: "component---src-pages-vouchers-js" */),
  "component---src-templates-booking-js": () => import("./../../../src/templates/booking.js" /* webpackChunkName: "component---src-templates-booking-js" */),
  "component---src-templates-venue-js": () => import("./../../../src/templates/venue.js" /* webpackChunkName: "component---src-templates-venue-js" */)
}

